* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #000;
  font-family: sans-serif;
  color: white;

}

main{
  width: 100vw;
  height: 100vh;
  overflow: hidden;

}


.fullscreen{
  display: flex;
  width: 100vw;
  height: 100vh;
  align-content: center;
}

.stack{
  display: block;
  margin: auto auto;
  height: auto;
  text-decoration: underline;
}

.stack:hover{
  cursor: pointer;
}

#info-wrap{
  display: block;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 1.5%;
  pointer-events: none;
}
#info-wrap span{
  width: 100%;
  float: left;
}
